.container {
  font-family: var(--font-proxima-nova);
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  row-gap: 30px;
  column-gap: 30px;
  column-gap: 2.5%; */
}
.box {
  background: #f6f7f8;
  box-shadow: 0px 3px 10px #00000029;
  /* height: 250px; */
  /* width: 220px; */
  padding: 20px 20px 30px;
  flex-basis: 18%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.5s ease-in-out;
}
.box:hover {
  scale: 0.9;
}
.img {
  object-fit: contain;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  height: 275px;
}
.text {
  font-size: 18px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .box {
    flex-basis: 23%;
  }
  .img {
    padding: 0;
  }
}
@media screen and (max-width: 700px) {
  .text {
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .box {
    flex-basis: 100%;
  }
  .img {
    height: 100px;
  }
}
