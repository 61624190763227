.container2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  row-gap: 80px;
  column-gap: 3%;
  margin-top: 100px;
  margin-bottom: 40px;

  padding: 0 10%;
}
