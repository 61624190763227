.section {
  /* max-width: 900px; */
  gap: 2%;
  margin: 0px auto 0px;
}
.section,
.sBox,
.dBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sBox,
.dBox {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  padding: 5px 15px;
  height: 40px;
}
.sImg,
.dBox {
  cursor: pointer;
}
.sBox {
  /* flex-basis: 100%; */
  justify-content: stretch;
  /* width: 100%; */
  gap: 10px;
  align-self: stretch;
  flex: 1;
}

.sImg {
  width: 20px;
  object-fit: contain;
}

.sText {
  width: 100%;
  font-size: 18px;
  line-height: 1.25;
}
.dBox {
  /* flex-basis: 38%; */
  font-size: 18px;
  justify-content: space-between !important;
}
.dGrade,
.dSubject {
  margin: 0 5px;
  border: none;
  width: 120px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
}
.divider {
  width: 1px;
  background: rgba(0, 0, 0, 0.5);
  height: 80%;
  margin: 0 5px;
}
/* .dGrade {
}
.dSubject {
} */

@media screen and (max-width: 600px) {
  .section {
    flex-direction: column;
    gap: 10px;
  }
  .dBox {
    justify-content: space-around !important;
    width: 100%;
  }
}
