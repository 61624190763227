.content1buttons,
.dbebutton,
.dbebuttoncontent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content1buttons {
  padding: var(--padding-xl) 0;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  align-items: end;
}
.ictbutton {
  background-color: var(--color-red-100);
  position: relative;
  border-radius: var(--br-xs);
  /* margin: auto; */
  flex-shrink: 0;
  z-index: 10;
}
.ictbuttoncontent {
  letter-spacing: 0.4px;
  font-family: var(--font-proxima-nova);
  color: var(--color-white);
  text-align: center;
}
.dbebutton:hover,
.ictbutton:hover {
  background-color: var(--color-red-300);
}
.dbebuttontext {
  position: relative;
  letter-spacing: 0.4px;
  /* line-height: 32px; */
  font-family: var(--font-proxima-nova);
  color: var(--color-white);
  text-align: center;
}
.dbebuttonimgIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  object-fit: cover;
}
.dbebuttoncontent {
  justify-content: center;
  gap: var(--gap-3xs);
}
.dbebutton {
  cursor: pointer;
  background-color: var(--color-red-100);
  border-radius: var(--br-xs);
  justify-content: center;
}
.dbebutton,
.ictbutton {
  border: 0;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  height: 50px;
  padding: 9px var(--padding-lg);
}

/* Button Ends */

/* backGround starts */
.content1 {
  flex: 1;
  position: absolute;
  background: linear-gradient(
    268.15deg,
    rgba(192, 57, 43, 0.05),
    rgba(22, 54, 123, 0.05) 50%,
    rgba(20, 54, 124, 0.05)
  );
  height: 100%;
  overflow: hidden;
  width: 100%;
  /* clip-path: polygon(0 50%, 100%0, 100% 50%, 0 100%); */
  transform: skewY(-10deg);
  top: 15%;
  z-index: 0;
}
/* backGround Ends */

.container {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: var(--spacing);
  margin-bottom: 50px;
  margin-top: 100px;
  line-height: 1.5;
}
.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.left {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  line-height: 1.25;
}
.title {
  font-size: 45px;
  color: #333333;
  margin: 0;
  text-align: left;
  font-weight: 600;
}
.para1,
.para2 {
  font-size: 25px;
  margin: 0;
  color: #6f6f6f;
  text-align: left;
}
.right {
  flex-basis: 45%;
  align-items: stretch;
  justify-content: stretch;
}
.threeInOne {
  width: 100%;
  height: 100%;
  /* border: 2px solid blue; */
  align-items: stretch;
  align-self: stretch;
  justify-content: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.rightBox {
  flex-basis: 33%;
  transform: skewY(-10deg);
  display: flex;
  /* border: 2px solid black; */
  height: 100%;
}
.rightImg {
  object-fit: cover;
  width: 100%;
  translate: 0 0 0;
  box-shadow: 7px 10px 5px #00000029;
  align-self: stretch;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 35px;
  }
}

@media screen and (max-width: 1000px) {
  .content1buttons {
    flex-direction: column;
    width: 100%;
  }
  .ictbutton,
  .dbebutton {
    width: 100%;
  }
  .dbebuttontext,
  .ictbuttoncontent {
    font-size: 16px;
  }
  .container {
    margin-top: 50px;
  }
  .box {
    flex-direction: column;
    gap: 50px;
  }
  .left {
    flex-basis: 100%;
    gap: 15px;
  }
  .right {
    flex-basis: 100%;
  }
  .title {
    font-size: 25px;
  }
  .title,
  .para1,
  .para2 {
    text-align: center;
  }

  .rightBox {
    height: 300px;
  }
}

@media screen and (max-width: 900px) {
  /* backGround Starts */
  .content1 {
    height: 100%;
  }
  /* backGround Ends */
}

@media screen and (max-width: 500px) {
  .dbebuttontext,
  .ictbuttoncontent {
    font-size: var(--font-size-6xs);
  }
  .rightBox {
    height: 200px;
  }
  .para1,
  .para2 {
    font-size: 16px;
  }
}
