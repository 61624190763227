.oContainer {
  font-family: var(--font-proxima-nova);
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
}
.box {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 10px #00000029;
  padding: 20px;
  gap: 20px;
}
.box:nth-child(odd) {
  flex-basis: 38%;
}
.box:nth-child(even) {
  flex-basis: 60%;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  /* margin-right: 30px; */
  align-items: center;
}
.topContent {
  flex: 1;
}
.heading {
  font-weight: bold;
  font-size: 18px;
}
.link,
.para {
  font-size: 16px;
}

.downloadButton {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  align-self: flex-end;
  font-size: 14px;
}

@media screen and (max-width: 1000px) {
  .box {
    flex-basis: 100% !important;
  }
}
