@keyframes pulsate {
  0% {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
.navLogoIcon {
  position: relative;
  width: 154px;
  height: 70px;
  flex-shrink: 0;
  cursor: pointer;
}
.homelink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.navLinkNoticeBoard {
  display: inline-block;
  width: auto;
}
.navLinkFaq:hover,
.navLinkHelp:hover,
.navLinkNoticeBoard:hover {
  color: var(--color-gray-600);
}
.navLinkFaq,
.navLinkHelp,
.navLinkNoticeBoard {
  text-decoration: none;
  position: relative;
  font-size: var(--font-size-2xs);
  line-height: 28.48px;
  font-family: var(--font-proxima-nova);
  color: var(--color-gray-500);
  text-align: center;
  cursor: pointer;
}
.navContentLinks1 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-md) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.login {
  position: relative;
  font-size: var(--font-size-5xs);
  letter-spacing: 0.4px;
  line-height: 28px;
  font-family: var(--font-proxima-nova);
  color: #333;
  text-align: center;
}
.navLoginButton {
  cursor: pointer;
  border: 0;
  padding: 5px 15px;
  background: var(--color-white) !important;
  border-radius: var(--br-xs);
  width: 100px;
  flex-shrink: 0;
  box-sizing: border-box;
  justify-content: center;
  font-weight: 500 !important;
}
.navLoginButton:hover {
  background: rgba(255, 255, 255, 0.834) !important;
  scale: 0.98;
}
.navContent,
.navContentLinks,
.navLoginButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navContentLinks {
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.navContent {
  flex: 1;
  justify-content: space-between;
  margin: var(--spacing);
  position: relative;
  z-index: 0;
}
.toogleBtnImageIcon {
  position: relative;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}
.toogleBtnImageIcon:hover {
  cursor: pointer;
  animation: 1s ease 0s 1 normal none pulsate;
  opacity: 1;
}
.navbar,
.toggleButton {
  flex-direction: row;
  justify-content: flex-start;
}
.toggleButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  display: none;
  align-items: flex-start;
  position: absolute;
  right: 5%;
  z-index: 1;
}
.navbar {
  align-self: stretch;
  background: linear-gradient(90deg, #4b65dc, #ed4264);
  display: flex;
  padding: var(--padding-sm) 0;
  align-items: center;
  position: relative;
  gap: var(--gap-xs);
}
@media screen and (max-width: 1100px) {
  .navLogoIcon {
    height: 70px;
  }
}
@media screen and (max-width: 1000px) {
  .navLogoIcon {
    width: 110px;
    height: 50px;
  }
  .login,
  .navLinkFaq,
  .navLinkNoticeBoard,
  .navLinkHelp {
    font-size: 15px;
  }
  .navLoginButton {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .navContentLinks {
    gap: var(--gap-xl);
  }
}
@media screen and (max-width: 800px) {
  .navContentLinks,
  .navContentLinks1 {
    flex-direction: column;
  }
  .navContentLinks {
    background-color: var(--color-turquoise);
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0-5%0 0;
    width: 50%;
  }
  .toogleBtnImageIcon {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 0;
  }
  .toggleButton {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 6%;
  }
}

@media screen and (max-width: 600px) {
  .navContent {
    margin-left: 35px !important;
  }
}
