.container {
  margin: 50px 10% 50px;
  display: flex;
  justify-content: center;
  font-family: var(--font-proxima-nova);
  position: relative;
  align-items: center;
  flex-direction: row;
}

.backButtonBox {
  position: absolute;
  left: 0;
}

.backButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: 0.2s linear;
}
.backButton:hover {
  scale: 0.97;
}
.btnImg {
  /* width: 30px;
  height: 30px;
  rotate: -90deg; */
  display: flex;
}
.btnText {
  font-size: 16px;
}
.heading {
  font-size: 35px;
  font-weight: bold;
  align-self: flex-end;
  align-content: center;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 25px;
  }
  .btnImg {
    /* width: 20px;
    height: 20px; */
  }
  .btnText {
    font-size: 14px;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    margin: 50px 5% 50px;
  }
}
@media screen and (max-width: 750px) {
  .backButtonBox {
    position: static;
    align-self: flex-start;
  }
  .container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
}
