.title {
  font-size: 40px;
  font-weight: bold;
  font-family: var(--font-proxima-nova);
  text-align: center;
  margin: 50px 0 40px 0;
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 30px;
  }
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }
}
