.topboximgIcon {
  width: 88px;
  height: 88px;
  flex-shrink: 0;
  overflow: hidden;
  margin-bottom: 35px;
}
.subtitle {
  margin: 0;
  position: relative;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-size: 22px;
}
.arrowIcon {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  overflow: hidden;
  transition: 0.5s;
}
.subbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.backgroundIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topboxContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  z-index: 1;
  padding: 50px 40px;
}
.topbox {
  flex-basis: 46%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-proxima-nova);
  cursor: pointer;
}
.topbox:hover .arrowIcon {
  translate: 7px 0;
}

@media screen and (max-width: 1400px) {
  .topboxContent {
    padding: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .topboxContent {
    padding: 20px 20px;
    gap: 20px;
  }
  .topbox {
    flex-basis: 47%;
  }
}
@media screen and (max-width: 800px) {
  .topboximgIcon {
    margin-bottom: 0px;
    width: 50px;
    height: 50px;
  }
  .subtitle {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .topboxContent {
    padding: 10px 10px;
  }
  .topbox {
    flex-basis: 100%;
  }
}
