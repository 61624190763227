.pageNumberTopBox {
  overflow: hidden;
  margin: 50px 0 30px;
}
.pageNumberBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
}
.pageNumber {
  padding: 3px 10px;
  color: blue;
  /* border: 1px solid rgba(0, 255, 51, 0.742); */
  border: 2px solid violet;
  margin: 0 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  scale: 0.8;
}
.pageNumber:hover {
  border: 2px solid rgba(0, 255, 51, 0.442);
  /* scale: 0.8; */
}

.active {
  /* border: 2px solid blue; */
  border: 2px solid violet;

  scale: 1;
}
